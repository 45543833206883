import actions from './actions'
import saga from '../GeneralListFunctions/sagaFactory'

const sagaConst = () =>
  saga(
    actions,
    () => 'v1/advertisement',
    (state) => state.home
  )
export default sagaConst
