import React from 'react'
import { View } from 'react-native'
import { Text } from 'react-native-paper'

function EmptyMessage({
  title,
  description,
}: {
  title?: string
  description?: string
}) {
  return (
    <View
      key="empty"
      style={{
        flex: 1,
        width: '100%',
        // height: '100%',
        justifyContent: 'center',
        // flexGrow: 1,
        alignItems: 'center',
        padding: 16,
        paddingTop: 56,
        paddingBottom: 56,
        flexDirection: 'column',
      }}
    >
      <Text style={{ fontSize: 19 }}>{title}</Text>
      <Text style={{ color: '#525252', marginTop: 6 }}>{description}</Text>
    </View>
  )
}

export default React.memo(EmptyMessage)
