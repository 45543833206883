import { baseStaticURL } from './Media.config'
import noImage from '../vectors/no-image.png'
import React from 'react'

function Media({ media, mediaWidth, renderPlaceholder = true, render }) {
  let source = media && media.originalUrl

  if (media && media.imageVariations) {
    let sortedVariations = media.imageVariations.sort(
      (a, b) => a.width - b.width
    )

    const bestVariation = sortedVariations.find(
      (iv) => (mediaWidth || 600) <= iv.width
    )

    if (bestVariation) {
      source = bestVariation && bestVariation.url
    }
  }

  if (source) {
    source = {
      uri: `${baseStaticURL}/${source}`,
    }
  } else if (media) {
    source = media
  } else if (renderPlaceholder) {
    source = noImage
  }

  if (!source) {
    return null
  }

  return render(source)
}

export default React.memo(Media)
