import { AppRegistry } from 'react-native'
import App from './App'
import './index.css'

import configureStore from './configureStore'
import { Provider } from 'react-redux'
import React from 'react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

Sentry.init({
  dsn: 'https://e2ff4e57e1ce4247aa1986f4dc09c1fd@o1015357.ingest.sentry.io/5980911',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

const store = configureStore()

function AppComp() {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}

AppRegistry.registerComponent('auctionwatch', () => AppComp)

AppRegistry.runApplication('auctionwatch', {
  rootTag: document.getElementById('root'),
})
