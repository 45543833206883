export const getErrorMessage = (
  error: { response: { message: any }; message: any },
  defaultError = 'Er is iets foutgegaan'
) => {
  if (error && error.response && error.response.message) {
    return error.response.message
  }
  if (error && error.message) {
    return error.message
  }
  return defaultError
}
