export const actionCreator = (type) => (payload, meta, error) => ({
  type,
  payload,
  meta,
  error: error || (payload && payload instanceof Error),
})

export const updatableListActions = (namespace) => {
  const RELOAD_LIST = `${namespace}_RELOAD_LIST`

  const LOAD_LIST = `${namespace}_LOAD_LIST`
  const LOADED_LIST = `${namespace}_LOADED_LIST`

  const LOAD_MORE_LIST = `${namespace}_LOAD_MORE_LIST`
  const LOADED_MORE_LIST = `${namespace}_LOADED_MORE_LIST`

  const LOAD = `${namespace}_LOAD`
  const LOADED = `${namespace}_LOADED`

  const SELECT = `${namespace}_SELECT`
  const TOGGLE_SELECT_ALL = `${namespace}_SELECT_ALL`
  const REMOVE_SELECTED = `${namespace}_REMOVE_SELECTED`

  const CREATE = `${namespace}_CREATE`
  const CREATED = `${namespace}_CREATED`

  const UPDATE = `${namespace}_UPDATE`
  const UPDATED = `${namespace}_UPDATED`
  const UNIQUEUPDATE = `${namespace}UNIQUEUPDATE`
  const UNIQUEUPDATED = `${namespace}UNIQUEUPDATED`

  const REMOVE = `${namespace}_REMOVE`
  const REMOVED = `${namespace}_REMOVED`

  const SET_DATA = `${namespace}_SET_DATA`
  const SAVED_STATE = `${namespace}_SAVED_STATE`
  const SAVE_STATE = `${namespace}_SAVE_STATE`
  const SAVING_STATE = `${namespace}_SAVING_STATE`

  const SAVE_TOKEN = `${namespace}_SAVE_TOKEN`
  const SAVED_TOKEN = `${namespace}_SAVED_TOKEN`

  const DOWNLOAD = `${namespace}_DOWNLOAD`
  const DOWNLOADED = `${namespace}_DOWNLOADED`
  const CLEAR_DOWNLOAD = `${namespace}_CLEAR_DOWNLOAD`
  // const SET_LIST_PARAMS = `${SET_LIST_PARAMS}_SET_DATA`
  const SOFT_RESET = `${namespace}_SOFT_RESET`

  return {
    SET_DATA,
    setData: actionCreator(SET_DATA),
    SAVE_TOKEN,
    saveToken: actionCreator(SAVE_TOKEN),
    SAVED_TOKEN,
    savedToken: actionCreator(SAVED_TOKEN),
    SAVED_STATE,
    savedState: actionCreator(SAVED_STATE),
    SAVE_STATE,
    saveState: actionCreator(SAVE_STATE),
    SAVING_STATE,
    savingState: actionCreator(SAVING_STATE),
    DOWNLOAD,
    download: actionCreator(DOWNLOAD),
    DOWNLOADED,
    downloaded: actionCreator(DOWNLOADED),
    CLEAR_DOWNLOAD,
    clearDownload: actionCreator(CLEAR_DOWNLOAD),
    RELOAD_LIST,
    reloadList: actionCreator(RELOAD_LIST),
    LOAD_LIST,
    loadList: actionCreator(LOAD_LIST),
    LOADED_LIST,
    loadedList: actionCreator(LOADED_LIST),
    LOAD_MORE_LIST,
    loadMoreList: actionCreator(LOAD_MORE_LIST),
    LOADED_MORE_LIST,
    loadedMoreList: actionCreator(LOADED_MORE_LIST),
    LOAD,
    load: actionCreator(LOAD),
    LOADED,
    loaded: actionCreator(LOADED),
    CREATE,
    create: actionCreator(CREATE),
    CREATED,
    created: actionCreator(CREATED),
    UPDATE,
    update: actionCreator(UPDATE),
    UPDATED,
    updated: actionCreator(UPDATED),
    UNIQUEUPDATE,
    uniqueUpdate: actionCreator(UNIQUEUPDATE),
    UNIQUEUPDATED,
    uniqueUpdated: actionCreator(UNIQUEUPDATED),
    REMOVE,
    remove: actionCreator(REMOVE),
    REMOVED,
    removed: actionCreator(REMOVED),
    SELECT,
    select: actionCreator(SELECT),
    TOGGLE_SELECT_ALL,
    toggleSelectAll: actionCreator(TOGGLE_SELECT_ALL),
    REMOVE_SELECTED,
    removeSelected: actionCreator(REMOVE_SELECTED),
    SOFT_RESET,
    softReset: actionCreator(SOFT_RESET),
  }
}
