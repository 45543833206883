import actions from './actions'
import saga from '../../GeneralListFunctions/sagaFactory'

const sagaObj = () =>
  saga(
    actions,
    () => 'v1/user',
    (state) => state.user
  )
export default sagaObj
