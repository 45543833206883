import React, { Component } from 'react'
import { Image, ImageSourcePropType, StyleSheet, View } from 'react-native'
import { Link, withRouter } from 'react-router-dom'
import {
  Avatar,
  List,
  Text,
  Title,
  TouchableRipple,
  withTheme,
} from 'react-native-paper'
// import Logo from './Logo'
import { getGravatar } from './helpers/gravatar'
import { push } from './Navigation'
import { Theme } from 'react-native-paper/lib/typescript/types'
import { AvatarImageSource } from 'react-native-paper/lib/typescript/components/Avatar/AvatarImage'
import HomeIcon from './vectors/home/home.png'
import { RootState } from './reducers'
import { connect } from 'react-redux'

const styles = StyleSheet.create({
  root: {
    position: 'relative',
    backgroundColor: '#fff',
    height: 70,
    width: '100%',
    flexDirection: 'row',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    elevation: 5,

    shadowOpacity: 0.05,
    shadowRadius: 4.65,
    zIndex: 1000,
    alignItems: 'center',
    // border: '1px solid black',
  },
  rootMedium: {
    paddingLeft: 16,
    paddingRight: 16,
    height: 70,
  },

  tabRoot: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 70,
  },
  tabRootMedium: { flexDirection: 'row', minWidth: 150 },
  tabRootLarge: { flexDirection: 'row', minWidth: 250, flexShrink: 0 },

  tab: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  tabLarge: { flexDirection: 'row' },

  tabLink: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  tabIcon: {
    width: 20,
    height: 20,
  },
  tabIconLarge: {
    width: 30,
    height: 30,
  },
  tabLabel: {
    marginTop: 6,
    fontSize: 12,
  },
  tabLabelLarge: {
    marginLeft: 12,
    fontSize: 14,
  },

  space: {
    flex: 1,
    flexDirection: 'row',
  },

  avatar: {
    marginLeft: 16,
  },

  search: {
    borderRadius: 60,
    elevation: 0,
    backgroundColor: '#f8f8f8',
    height: 46,
    fontSize: 16,
    flex: 1,
    minWidth: 350,
  },
  tabMedium: {},
  logo: { flexDirection: 'row' },
})
type TabType = {
  label: string
  icon: ImageSourcePropType
  selected: boolean
  theme: Theme
  url: string
  isMedium: boolean
  isLarge: boolean
  componentId: string
}

function Tab({
  label,
  icon,
  selected,
  theme,
  url,
  isMedium,
  isLarge,
  componentId,
}: TabType) {
  // @ts-ignore
  return (
    <TouchableRipple
      onPress={() => {
        push(componentId, {
          component: { name: url },
        })
      }}
      style={[
        styles.tabRoot,
        isMedium && styles.tabRootMedium,
        isLarge && styles.tabRootLarge,
      ]}
      accessibilityLabel={`Go to ${label}`}
      // @ts-ignore
      accessibilityTraits={'button'}
      accessibilityComponentType="button"
      accessibilityRole="button"
    >
      <View
        style={[
          styles.tab,
          isMedium && styles.tabMedium,
          isLarge && styles.tabLarge,
        ]}
      >
        {/*@ts-ignore*/}
        <Image
          source={icon}
          style={[
            styles.tabIcon,
            isLarge && styles.tabIconLarge,
            selected && { tintColor: theme.colors.primary },
          ]}
          resizeMode="center"
        />
        <Text
          style={[
            styles.tabLabel,
            isLarge && styles.tabLabelLarge,
            selected && { color: theme.colors.primary },
          ]}
        >
          {label}
        </Text>
      </View>
    </TouchableRipple>
  )
}

const isSelected = (path: string, location: { pathname: string }) => {
  if (location && location.pathname) {
    return location.pathname.includes(path)
  }
  return false
}

export const locationIncludes = (location: { pathname: string }, v: string) => {
  return !!(location && location.pathname && location.pathname.includes(v))
}

class TabMenu extends Component<any> {
  // _pressAccount = () => {
  //   push(this.props.componentId, {
  //     component: {
  //       name: ACCOUNT_SCREEN_ROUTE,
  //     },
  //   })
  // }
  // _pressSearch = () => {
  //   push(this.props.componentId, {
  //     component: {
  //       name: SEARCH_ROUTE,
  //     },
  //   })
  // }
  render() {
    const {
      organization,
      user,
      theme,
      location,
      isLarge,
      isMedium,
      // searchValue,
    } = this.props

    if (locationIncludes(location, 'auth')) {
      return null
    }
    if (locationIncludes(location, 'change-password')) {
      return null
    }
    if (locationIncludes(location, 'request-account')) {
      return null
    }
    if (locationIncludes(location, 'forgot-password')) {
      return null
    }

    const tabProps = {
      isLarge,
      isMedium,
      theme,
    }

    return (
      <View style={[styles.root, isMedium && styles.rootMedium]}>
        {isLarge || isMedium ? (
          <Link to={'/'} style={{ textDecoration: 'none' }}>
            <View style={styles.logo}>
              <Title
                style={{
                  fontSize: 48,
                  color: '#505050',
                  // fontWeight: 'bold',
                  // textDecorationLine: 'none',
                  // textDecorationColor: undefined,
                  // textDeco: 'none',
                }}
              >
                AuctionWatch
              </Title>
              <Text
                style={{
                  color: '#505050',
                  fontSize: 14,
                  // marginTop: 15,
                  marginLeft: 8,
                }}
              >
                app
              </Text>
            </View>
          </Link>
        ) : null}

        {!isMedium && user && organization ? (
          <>
            <Tab
              url={'/'}
              icon={HomeIcon as ImageSourcePropType}
              label={'Home'}
              selected={isSelected('/', location)}
              componentId={this.props.componentId}
              {...tabProps}
            />
            {/*<Tab*/}
            {/*  url={'/account'}*/}
            {/*  icon={AccountIcon as ImageSourcePropType}*/}
            {/*  label={'Account'}*/}
            {/*  selected={isSelected('/account', location)}*/}
            {/*  componentId={this.props.componentId}*/}
            {/*  {...tabProps}*/}
            {/*/>*/}
          </>
        ) : null}

        {isMedium ? <View style={styles.space} /> : null}
        {
          <>
            {!isLarge && isMedium && user && user.email ? (
              <List.Item
                title={undefined}
                left={() => (
                  <Avatar.Image
                    size={46}
                    source={
                      getGravatar(user.email, 35 * 3) as AvatarImageSource
                    }
                    style={styles.avatar}
                  />
                )}
                onPress={() => null}
              />
            ) : null}
            {isLarge && user && organization ? (
              <List.Item
                left={() => (
                  <>
                    <Avatar.Image
                      size={46}
                      source={
                        getGravatar(user.email, 35 * 3) as AvatarImageSource
                      }
                      style={styles.avatar}
                    />
                  </>
                )}
                // title={[user.first_name, user.preposition, user.last_name]
                //   .filter((n) => n)
                //   .join(' ')}
                title={'USERNAME'}
                // description={organization.name}
                description={'organization.name'}
                onPress={() => null}
              />
            ) : null}
          </>
        }
      </View>
    )
  }
}

const mapStateToProps = (state: RootState, props: any) => ({
  // user: (state.auth && state.auth.user && state.auth.user.user) || {},
  // organization: state.auth && state.auth.user && state.auth.user.organization,
  // searchValue: state.search.searchValue.reducerValue,
})
export default withRouter(connect(mapStateToProps)(withTheme(TabMenu)))
