export const actionCreator = (type: string) => (
  payload?: any,
  meta?: any,
  error?: any
) => ({
  type,
  payload,
  meta,
  error: error || (payload && payload instanceof Error),
})
