// @ts-ignore
import redirectWhenAuthenticatedHOC from 'react-auth/lib/hocs/redirectWhenAuthenticatedHOC'
// @ts-ignore
import requireLoginHOC from 'react-auth/lib/hocs/requireLoginHOC'

import HomeScreen from './Home/Home'
import LoginScreen from './Auth/Login'
import ChangePasswordScreen from './Auth/ChangePassword'
import ForgotPasswordScreen from './Auth/ForgotPassword'
import { pushAuth, setHome } from './Navigation'
import { ClassicComponent, FunctionComponent } from 'react'
import { ConnectedComponent } from 'react-redux'
import Logout from './Auth/Logout'

const redirectWhenAuthenticated = (
  component: FunctionComponent | ClassicComponent | ConnectedComponent<any, any>
) => {
  return redirectWhenAuthenticatedHOC(component, setHome)
}

const requireLogin = (
  component: FunctionComponent | ClassicComponent | ConnectedComponent<any, any>
) => {
  if (process.env.REACT_APP_DISABLE_AUTHENTICATION === 'true') {
    return component
  }
  return requireLoginHOC(component, pushAuth, null, true)
}
export const HOME_SCREEN_ROUTE = `/`
export const CHANGE_PASSWORD_SCREEN_ROUTE = `/change-password`
export const FORGOT_PASSWORD_SCREEN_ROUTE = '/forgot-password'
export const AUTH_SCREEN_ROUTE = '/auth'
export const LOGOUT_SCREEN_ROUTE = '/logout'

export type RouteType = {
  path: string
  pathOnWeb?: string
  exact?: boolean
  component: any
  deepLinkWithoutLogin?: boolean
}
export const routeObjectToWeb = (lr: RouteType) => ({
  key: lr.path,
  path: lr.pathOnWeb || lr.path,
  exact: lr.exact,
  component: lr.component,
})

export const listRoutes: RouteType[] = [
  {
    path: AUTH_SCREEN_ROUTE,
    exact: true,
    deepLinkWithoutLogin: true,
    component: redirectWhenAuthenticated(LoginScreen),
  },

  {
    path: FORGOT_PASSWORD_SCREEN_ROUTE,
    exact: true,
    deepLinkWithoutLogin: true,
    component: redirectWhenAuthenticated(ForgotPasswordScreen),
  },
  {
    path: LOGOUT_SCREEN_ROUTE,
    component: requireLogin(Logout),
  },
  {
    path: CHANGE_PASSWORD_SCREEN_ROUTE,
    exact: true,
    deepLinkWithoutLogin: true,
    pathOnWeb: `${CHANGE_PASSWORD_SCREEN_ROUTE}/:secret?`,
    component: redirectWhenAuthenticated(ChangePasswordScreen),
  },
  {
    path: HOME_SCREEN_ROUTE,
    exact: true,
    pathOnWeb: `${HOME_SCREEN_ROUTE}`,
    component: requireLogin(HomeScreen),
  },
]

export const detailRoutes: RouteType[] = []

export const allRoutes = detailRoutes.concat(listRoutes)
