import a from './actions'
import pagedList from '../../GeneralListFunctions/pagedList'
import crud from '../../GeneralListFunctions/crud'
import { combineReducers } from 'redux'
import { UserType } from '../../reducers'

const reducer = combineReducers({
  list: pagedList<UserType>({
    load: a.LOAD_LIST,
    loaded: a.LOADED_LIST,
    loadMore: a.LOAD_MORE_LIST,
    loadedMore: a.LOADED_MORE_LIST,
  }),
  crud: crud<UserType>({
    load: a.LOAD,
    loaded: a.LOADED,
    update: a.UPDATE,
    updated: a.UPDATED,
    create: a.CREATE,
    created: a.CREATED,
    remove: a.REMOVE,
    removed: a.REMOVED,
  }),
})
export default reducer
