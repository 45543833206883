import { createStore, applyMiddleware, compose } from 'redux'

import rootReducer from './reducers'
import mySaga from './sagas'

import createSagaMiddleware from 'redux-saga'
declare global {
  interface Window {
    store: any
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
  }
}

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

export default function configureStore() {
  // create the saga middleware
  const sagaMiddleware = createSagaMiddleware()

  // mount it on the Store
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  )

  // debugging
  window.store = store

  // then run the saga
  sagaMiddleware.run(mySaga)

  return store
}

const initialState = {}

// const initialState = process.env.NODE_ENV === 'development' ? dev : {}
