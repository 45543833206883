import { Platform } from 'react-native'

const fonts = Platform.select({
  web: {
    regular: {
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,\n" +
        "    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
      fontWeight: '500',
    },
    medium: {
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,\n" +
        "    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
      fontWeight: '600',
    },
    light: {
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,\n" +
        "    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
      fontWeight: '300',
    },
    thin: {
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,\n" +
        "    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
      fontWeight: '100',
    },
  },
  ios: {
    regular: {
      fontFamily: 'Montserrat',
      fontWeight: '500',
    },
    medium: {
      fontFamily: 'Montserrat',
      fontWeight: '600',
    },
    light: {
      fontFamily: 'Montserrat',
      fontWeight: '400',
    },
    thin: {
      fontFamily: 'Montserrat',
      fontWeight: '100',
    },
  },
  default: {
    regular: {
      fontFamily: 'Montserrat-Medium',
      // fontWeight: 'normal',
    },
    medium: {
      fontFamily: 'Montserrat-SemiBold',
      // fontWeight: 'normal',
    },
    light: {
      fontFamily: 'Montserrat-Regular',
      // fontWeight: 'normal',
    },
    thin: {
      fontFamily: 'Montserrat-Thin',
      // fontWeight: 'normal',
    },
  },
})

export default fonts
