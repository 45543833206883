import React from 'react'
import Resizer from '../components/Resizer'
import GeneralList from '../GeneralListFunctions/GeneralList'
import { useDispatch, useSelector } from 'react-redux'
import actions from './actions'
import { useTheme } from 'react-native-paper'
import ListItem from '../components/ListItem'
import { AppState, Platform, ScrollView } from 'react-native'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'

type HomeProps = {
  componentId: string
  safe: any
  token: string
  itemId: string
}

// const styles = StyleSheet.create({
//   fab: {
//     position: 'absolute',
//     borderRadius: 5,
//     shadowColor: '#000',
//     shadowOffset: {
//       width: 0,
//       height: 3,
//     },
//
//     elevation: 5,
//
//     shadowOpacity: 0.05,
//     shadowRadius: 4.65,
//     // backgroundColor: theme.palette.primary.main,
//   },
//
//   fabPusher: {
//     paddingBottom: 16 + 36 + 16,
//   },
// })
function Home({ componentId, safe, token, itemId }: HomeProps) {
  const authUser = useSelector((state: any) => state.auth.user)
  const dispatch = useDispatch()

  const reloadList = () => {
    dispatch(
      actions.reloadList({
        params: { sort: '-date,-createdAt', preloadAll: true },
      })
    )
  }

  React.useEffect(() => {
    const eventListener = AppState.addEventListener('change', reloadList)
    return () => {
      eventListener && eventListener.remove()
    }
  })

  React.useEffect(() => {
    const body = {
      userId: authUser.user.id,
      os: Platform.OS,
      deviceToken: token,
    }
    console.log('seding', body)
    dispatch(actions.saveToken(body))
  }, [token, authUser, dispatch])

  const theme = useTheme()
  const list = useSelector((state: any) => state.home)
  console.log(list)

  const renderItem = React.useCallback((it: any) => {
    const { item } = it
    return <ListItem item={item} showImage key={item.url} />
  }, [])
  const getParams = (ignore: any, inputSearch: string) => {
    // search = inputSearch
    return {
      'search[search]': inputSearch,
      preloadAll: true,
    }
  }

  return Platform.OS === 'web' ? (
    <ScrollView>
      <Resizer>
        <GeneralList
          {...list}
          itemId={itemId}
          loadList={(params: any) =>
            dispatch(
              actions.loadList({
                params: { sort: '-date,-createdAt', ...params.params },
              })
            )
          }
          // searchPlaceHolder={''}
          componentId={componentId}
          renderItem={renderItem}
          getParams={getParams}
          fabColor={theme.colors.primary}
        />
      </Resizer>
    </ScrollView>
  ) : (
    <GeneralList
      key={'generallist'}
      {...list}
      itemId={itemId}
      loadList={(params: any) =>
        dispatch(
          actions.loadList({
            params: { sort: '-date', ...params.params },
          })
        )
      }
      // searchPlaceHolder={''}
      componentId={componentId}
      renderItem={renderItem}
      getParams={getParams}
      fabColor={theme.colors.primary}
      reloadList={(params: any) => {
        return dispatch(
          actions.reloadList({
            params: { sort: '-date,-createdAt', ...params.params },
          })
        )
      }}
    />
  )
}

export default safeAreaHOC(Home)
