import React, { useCallback, useEffect } from 'react'
import { Dimensions, Platform, StyleSheet, View } from 'react-native'
import widthAndHeightHOC from './widthAndHeightHOC'
import { useDispatch } from 'react-redux'
import { update } from './actions'

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
})

function WidthAndHeight({ render, listenToChanges, ...rest }: any) {
  const dispatch = useDispatch()

  const onChange = useCallback(() => {
    const window = Dimensions.get('window')
    const screen = Dimensions.get('screen')

    dispatch(
      update({
        width: window.width,
        height:
          Platform.OS === 'web'
            ? window.height
            : Math.max(window.height, screen.height),
      })
    )
  }, [dispatch])

  useEffect(() => {
    if (listenToChanges) {
      const listener = Dimensions.addEventListener('change', onChange)
      return () => listener.remove()
    }
  }, [onChange, listenToChanges])

  return <View style={styles.root}>{render(rest)}</View>
}

export default widthAndHeightHOC(WidthAndHeight)
