import { call, put, select, take, takeLatest } from 'redux-saga/effects'
// import { Platform } from 'react-native'
import { fetchListener } from 'react-auth/lib/sagas/authentication'
import api from 'react-api'

export const numberPerRequest = 200
// DISABLE THIS IN PROD, NECESSARY FOR users in test
// export const numberPerRequest = Platform.OS === 'web' ? 50 : 100

function* load(actions, getPath, getState, action) {
  try {
    const state = yield select((state) => state)
    const path = getPath(state)
    const { crud } = yield select((state) => getState(state))
    const { object } = crud
    if ((!object || !object.id) && !action.payload.id) {
      yield put(actions.loaded(object))
      return
    }

    const { params, fetchOptions, cachedFromList } = action.meta || {}
    if (cachedFromList) {
      yield put(actions.loaded(object))
      // return
    }
    const id = object.id ? object.id : action.payload.id
    const response = yield call(fetchListener, {
      path: `${path}/${id}`,
      params,
      ...fetchOptions,
    })

    yield put(actions.loaded(response))
  } catch (e) {
    yield put(actions.loaded(e, null, true))
  }
}

function* loadList(actions, getPath, getState, action, loadMore) {
  try {
    console.log('loading or reloading')
    const state = yield select((state) => state)
    const url = getPath(state)
    const { list } = yield select((state) => getState(state))

    const meta = action.meta || {}
    let status
    if (action && action.payload && action.payload.status) {
      status = action.payload.status
    }
    let params = status
      ? {
          limit: numberPerRequest,
          number: numberPerRequest,
          ...list.params,
          ...meta.params,
          status,
        }
      : {
          limit: numberPerRequest,
          number: numberPerRequest,
          ...list.params,
          ...meta.params,
        }

    if (loadMore) {
      const { offset, total } = list
      if (offset > total) {
        return
      }
      params.offset = (offset || 0) + numberPerRequest
    }
    const response = yield call(fetchListener, {
      method: 'GET',
      path: url,
      params,
    })
    yield put(
      loadMore
        ? actions.loadedMoreList(response, meta)
        : actions.loadedList(response, meta)
    )
  } catch (e) {
    yield put(
      loadMore
        ? actions.loadedMoreList(e, null, true)
        : actions.loadedList(e, null, true)
    )
  }
  if (actions.LOAD_MORE_LIST) {
    yield take(actions.LOAD_MORE_LIST)
    yield call(loadList, actions, getPath, getState, action, true)
  }
}

function* update(actions, getPath, getState, action) {
  try {
    const state = yield select((state) => state)
    const path = getPath(state)
    const { crud } = yield select((state) => getState(state))
    const { object } = crud
    const { params } = action.meta || {}
    let fullBody = object
    if (action && action.payload && action.payload.id) {
      fullBody = { ...fullBody, ...action.payload }
    }
    const response = yield call(fetchListener, {
      path: `${path}/${object.id}`,
      method: 'PUT',
      body: fullBody,
      params,
    })
    // if (Object.keys(fullBody).length > 0) {
    //   const uuid = fullBody && fullBody.uuid ? fullBody.uuid : uuidv4()
    //   localforage.getItem('qcIds').then((array) => {
    //     localforage.setItem('qcIds', [...(array || []), uuid])
    //   })
    //   localforage.setItem('qc_' + uuid, { ...fullBody, uuid: uuid })
    // }
    yield put(actions.updated(response))
  } catch (e) {
    yield put(actions.updated(e, null, true))
  }
}

function* remove(actions, getPath, getState, action) {
  try {
    const state = yield select((state) => state)
    const path = getPath(state)
    const { crud } = yield select((state) => getState(state))
    const { object } = crud
    const { params, id } = action.meta || {}
    // const response =
    if (object && object.id) {
      yield call(fetchListener, {
        path: `${path}/${object.id}`,
        method: 'DELETE',
        params,
      })
    } else if (id) {
      yield call(fetchListener, {
        path: `${path}/${id}`,
        method: 'DELETE',
        params,
      })
    } else {
      yield call(fetchListener, {
        path: `${path}`,
        method: 'DELETE',
        params,
        body: action.payload,
      })
    }

    yield put(actions.removed(object))
  } catch (e) {
    console.log(e)
    yield put(actions.removed(e, null, true))
  }
}

function* create(actions, getPath, getState, action) {
  try {
    const state = yield select((state) => state)
    const url = getPath(state)
    const { crud } = yield select((state) => getState(state))
    const { object } = crud
    const { params, extraBody } = action.meta || {}
    const disableAuthentication =
      (action && action.payload && action.payload.disableAuthentication) ||
      false

    let body =
      extraBody && extraBody.dontAddObject
        ? extraBody
        : { ...object, ...extraBody }

    let response
    if (disableAuthentication) {
      response = yield call(api, {
        path: url,
        method: 'POST',
        body,
        params,
      })
    } else {
      response = yield call(fetchListener, {
        path: url,
        method: 'POST',
        body,
        params,
      })
    }

    // if (Object.keys(body).length > 0) {
    //   const uuid = body && body.uuid ? body.uuid : uuidv4()
    //   localforage.getItem('qcIds').then((array) => {
    //     localforage.setItem('qcIds', [...(array || []), uuid])
    //   })
    //   localforage.setItem('qc_' + uuid, { ...body, uuid: uuid })
    // }

    yield put(actions.created(response))
  } catch (e) {
    yield put(actions.created(e, null, true))
  }
}

function* saveToken(actions, getPath, getState, action) {
  try {
    let body = action.payload

    let response = yield call(fetchListener, {
      path: `v1/notification/push/subscription`,
      method: 'POST',
      body,
    })

    yield put(actions.savedToken(response))
  } catch (e) {
    yield put(actions.savedToken(e, null, true))
  }
}

export default function* pagedList(
  actions,
  getPath = (state) => '',
  getState = (state) => console.log('get state not implemented'),
  reloadConfig
) {
  let reloadActions = [actions.CREATED, actions.UPDATED, actions.REMOVED]
  if (reloadConfig && reloadConfig.removeOthers) {
    reloadActions = reloadConfig.reloadActions
  } else if (reloadConfig) {
    reloadActions = [...reloadConfig.reloadActions, reloadActions]
  }

  yield takeLatest(
    [actions.LOAD_LIST, actions.RELOAD_LIST, ...reloadActions].filter((n) => n),
    loadList,
    actions,
    getPath,
    getState
  )

  yield takeLatest(actions.LOAD, load, actions, getPath, getState)
  yield takeLatest(actions.UPDATE, update, actions, getPath, getState)
  yield takeLatest(actions.CREATE, create, actions, getPath, getState)
  yield takeLatest(actions.REMOVE, remove, actions, getPath, getState)
  yield takeLatest(actions.SAVE_TOKEN, saveToken, actions, getPath, getState)
}
