import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { withRouter, Router, Switch, Route } from 'react-router-dom'
import { history } from './Navigation.web'
import { allRoutes, routeObjectToWeb, RouteType } from './Routes.config'
import TabMenu from './TabMenu'

const styles = StyleSheet.create({
  root: {
    flexDirection: 'column',
    minHeight: '100%',
    maxHeight: '100%',
    height: '100%',
    overflow: 'hidden',
    flex: 1,
  },

  smallContent: {
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
})

const renderRoutes = (routes: RouteType[], smallScreen = false) =>
  // @ts-ignore
  routes.map((lr) => <Route {...routeObjectToWeb(lr)} />)
type AppRouterProps = {
  width: number
  height: number
  isMedium: boolean
  isLarge: boolean
  location: any
}
class AppRouter extends Component {
  render() {
    const { width, height, isMedium, isLarge }: AppRouterProps = this
      .props as AppRouterProps
    const tabProps = {
      isMedium,
      isLarge,
      width,
      height,
    }
    return (
      <Router history={history}>
        <View style={styles.root}>
          {isMedium ? <TabMenu {...tabProps} /> : null}

          <View style={styles.smallContent}>
            <ScrollToTop>
              <Switch>{renderRoutes(allRoutes, true)}</Switch>
            </ScrollToTop>
          </View>

          {!isMedium ? <TabMenu {...tabProps} /> : null}
        </View>
      </Router>
    )
  }
}

export default AppRouter

class ScrollToTopDummy extends Component {
  componentDidUpdate(prevProps: { location: any }) {
    if ((this.props as AppRouterProps).location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }
  render() {
    return this.props.children
  }
}
// @ts-ignore
const ScrollToTop = withRouter(ScrollToTopDummy)
