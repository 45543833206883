import { Reducer } from 'redux'

export type ReducerType<T> = {
  params: {}
  data: T[]
  pagination: any
  loading: boolean
  loaded: boolean
  loadingMore: boolean
  canLoadMore: boolean
  error: boolean
  errorMore: boolean
}
function getInitialState<T>(): ReducerType<T> {
  return {
    params: {},
    data: [],
    pagination: {},
    loading: false,
    loaded: false,
    loadingMore: false,
    canLoadMore: false,
    error: false,
    errorMore: false,
  }
}

export function getData(payload: any) {
  let data = []
  if (payload && payload.data && Array.isArray(payload.data)) {
    data = payload.data
  } else if (payload && Array.isArray(payload)) {
    data = payload
  }
  return data
}

function listReducer<T>(actionTypes: any): Reducer<ReducerType<T>, any> {
  return function (
    state = getInitialState<T>(),
    { type, payload, error }: any
  ) {
    switch (type) {
      case 'RESET': {
        return getInitialState<T>()
      }
      case actionTypes.load: {
        const params = payload && payload.params ? payload.params : state.params
        const doNotEmptyList =
          payload && payload.doNotEmptyList ? payload.doNotEmptyList : {}
        return {
          ...state,
          params,
          data: doNotEmptyList ? state.data : [],
          pagination: {},
          error: false,
          loading: true,
          loaded: false,
          loadingMore: false,
        }
      }
      case actionTypes.loaded:
        return error
          ? {
              ...state,
              data: [],
              pagination: {},
              loading: false,
              loaded: false,
              error: true,
              canLoadMore: false,
            }
          : {
              ...state,
              data: getData(payload),
              pagination: payload.pagination || {},
              loaded: true,
              loading: false,
              error: false,
              canLoadMore: payload.pagination
                ? payload.pagination.offset + payload.pagination.limit <
                  payload.pagination.total
                : false,
            }
      case actionTypes.loadMore:
        const params = payload && payload.params ? payload.params : state.params
        return {
          ...state,
          params,
          loadingMore: true,
        }

      case actionTypes.loadedMore:
        return error
          ? {
              ...state,
              loadingMore: false,
              errorMore: true,
              canLoadMore: false,
            }
          : {
              ...state,
              data: [...state.data, ...getData(payload)],
              pagination: {
                ...state.pagination,
                ...(payload.pagination || {}),
              },
              loadingMore: false,
              errorMore: false,
              canLoadMore:
                payload.pagination &&
                payload.pagination.offset + payload.pagination.limit <
                  payload.pagination.total,
            }

      default:
        return state
    }
  }
}

export default listReducer
