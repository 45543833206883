import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../reducers'
export const mediumWidth = 700
export const largeWidth = 1150

const wrappedComponent = (WrappedComponent: any) => {
  function HOC(props: any) {
    const width = useSelector((state: RootState) => state.widthAndHeight.width)
    const height = useSelector(
      (state: RootState) => state.widthAndHeight.height
    )
    const isMedium = width >= mediumWidth
    const isLarge = width >= largeWidth
    return (
      <WrappedComponent
        {...props}
        width={width}
        height={height}
        isMedium={isMedium}
        isLarge={isLarge}
      />
    )
  }
  return HOC
}

export default wrappedComponent
