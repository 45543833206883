import { combineReducers, Reducer } from 'redux'

// @ts-ignore
import auth from 'react-auth/lib/reducers/authentication'
// @ts-ignore
import register from 'react-auth/lib/reducers/register'
// @ts-ignore
import forgotPassword from 'react-auth/lib/reducers/forgotPassword'
import widthAndHeight from './WidthAndHeight/reducer'
import home from './Home/reducer'
import notification from './Notifications/reducer'
import user from './Auth/User/reducer'

const rootReducer = combineReducers({
  auth: auth as AuthReducerType,
  register: register as RegisterReducerType,
  forgotPassword: forgotPassword as ForgotPasswordReducerType,
  notification,
  user,
  home,
  widthAndHeight,
})

export type RootState = ReturnType<typeof rootReducer>

export type AttributeKey = string
export type AttributeType = {
  id?: number
  key: AttributeKey
  value: any
  type: 'string' | 'media' | 'attributes' | 'entity' | 'boolean' | 'integer'
}

export type AddressType = {
  id: undefined | number
  type: 'home' | 'work' | 'undefined'
  street: string
  houseNumber: string
  city: string
  postalCode: string
  latitude: number
  longitude: number
  country: string
  province?: string
}

export type UserType = {
  addresses: AddressType[] | null
  phone_numbers: [] | null
  attributes: AttributeType[] | null
  gender: 'U'
  initials: string
  first_name: string
  preposition: string
  last_name: string
  email: string
  company: { name: string }
  sepa: string
  externalId: string
  id: number
  createdAt: number
  updatedAt: number
  organization_id: number
  rate: number
  billable: boolean
  language: string
  status: string
  function: string
}
export type OrganizationType = {
  id: number
  name: string
  email: string
  phone: string
  settings: { realTimePrice?: string; enableCenterBlock?: string } | null
  addresses: AddressType[] | null
  identificationNumber: string
  vatNumber: string
  ibanNumber: string
  logoUrl: string
  organizationGroups: null
  status: string
  website: string
  invoiceEmail: string
  createdAt: number
  updatedAt: number
}
export type AuthUserType = {
  user_id: number
  roles: { name: string }[] | null
} & UserType

export type AuthStateType = {
  restoring: boolean
  restoringError: boolean
  restored: boolean
  refreshing: boolean
  updating: boolean
  loading: boolean
  refreshingError: boolean
  tokens: {
    idToken: string
    accessToken: string
    refreshToken: string
  }
  user: {
    user: AuthUserType | null
    organization: OrganizationType | null
  } | null
  authenticating: boolean
  authenticatingError: boolean
  authenticated: boolean
  tokenValidated: boolean
  tokenValidating: boolean
  tokenValidationError: boolean
  loginStatus: 'logged_in'
  accountCompleted: boolean
}
export type AuthReducerType = Reducer<AuthStateType>

export type RegisterStateType = {
  data: {}
  registering: boolean
  registered: boolean
  sending: boolean
  error: boolean
}
export type RegisterReducerType = Reducer<RegisterStateType>
export type ForgotPasswordStateType = {
  requesting: boolean
  requestingError: boolean
  requested: boolean
  changing: boolean
  changingError: boolean
  changed: boolean
}
export type ForgotPasswordReducerType = Reducer<ForgotPasswordStateType>

export default rootReducer
