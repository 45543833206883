import React from 'react'
import { Platform, View } from 'react-native'
// @ts-ignore
import Routes from './Routes'
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper'
import { Provider } from 'react-redux'
import fonts from './fonts'
import WidthAndHeight from './WidthAndHeight/WidthAndHeight'
import NotificationContainer from './Notifications/NotificationContainer'
// @ts-ignore
import iconFont from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'
import configureStore from './configureStore'

const theme = {
  ...DefaultTheme,
  // dark: true,
  colors: {
    ...DefaultTheme.colors,
    background: '#F3F3F3',
    accent: '#707070',
    primary: '#067377',
    secondary: '#FFFFFF',
  },
  roundness: 4,
  fonts,
}
const store = configureStore()

export const HOC = (WrappedComponent: any) => {
  function Wrapper({ ...rest }) {
    return (
      <Provider store={store}>
        <View
          style={
            Platform.OS === 'web'
              ? { flex: 1, maxHeight: '100vh' }
              : { flex: 1 }
          }
        >
          <PaperProvider
            // @ts-ignore
            theme={theme}
          >
            {Platform.OS === 'web' ? (
              <style type="text/css">{`
                @font-face {
                  font-family: 'MaterialCommunityIcons';
                  src: url(${iconFont});
                }
              `}</style>
            ) : null}
            <WidthAndHeight
              listenToChanges
              render={(props: any) => [
                <WrappedComponent key="content" {...rest} {...props} />,
                <NotificationContainer key="notifications" {...props} />,
              ]}
            />
          </PaperProvider>
        </View>
      </Provider>
    )
  }

  if (WrappedComponent) {
    Wrapper.options = WrappedComponent.options
  }
  return Wrapper
}

export default HOC(Routes)
