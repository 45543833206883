import React from 'react'
import { Image, Linking, Platform, StyleSheet, View } from 'react-native'
import { Divider, Text, TouchableRipple } from 'react-native-paper'
import { fromTimestamp, getFriendlyDate } from '../dayjs'
import Media from './Media'

const styles = StyleSheet.create({
  root: {
    // borderRadius: 10,
    height: 450,
  },
  rootInner: {
    padding: 16,
  },

  middle: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 6,
    // marginBottom: 700,
  },
  right: {
    fontSize: 16,
    padding: 16,
    paddingTop: 0,
    alignSelf: 'center',
    // flex: 1,
  },

  brand: { fontSize: 13, fontWeight: '400', marginTop: 6 },
  name: { fontSize: 16, fontWeight: '600', paddingTop: 6, paddingBottom: 6 },
  sku: { opacity: 0.5, fontSize: 13, fontWeight: '400', marginBottom: 6 },
  imageContainer: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  image: {
    maxHeight: 300,
    height: 300,
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%',
  },
  total: {
    minWidth: 80,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingRight: 12,
  },
  totalText: {
    fontSize: 16,
  },
})

function ListItem({
  showImage,
  testID,
  item,
}: {
  showImage?: boolean
  testID?: string
  item: any
}) {
  const edit = React.useCallback(() => {
    if (Platform.OS === 'web') {
      window.open(item.url, '_')
    } else {
      Linking.openURL(item.url)
    }
  }, [item.url])

  return (
    <>
      <TouchableRipple
        key={item.url + '_list_item'}
        borderless={true}
        // useForeground={true}
        onPress={edit}
        accessibilityLabel={`hi native`}
        // accessibilityTraits={'button'}
        // accessibilityComponentType="button"
        accessibilityRole="button"
        style={styles.root}
        testID={testID}
      >
        <View
          style={[
            styles.rootInner,
            { paddingLeft: 4, paddingRight: 4 },
            { flexDirection: 'column' },
            { backgroundColor: '#FFF' },
          ]}
        >
          <View style={styles.middle}>
            <>
              {showImage && (
                <View style={styles.imageContainer}>
                  <Media
                    media={
                      item.photoLink && item.photoLink !== ''
                        ? { uri: item.photoLink }
                        : null
                    }
                    mediaWidth={500}
                    renderPlaceholder={true}
                    render={(source: any) => (
                      <Image
                        source={source}
                        style={styles.image}
                        resizeMode="contain"
                      />
                    )}
                  />
                </View>
              )}
              <Text style={[styles.right]}>{item.price}</Text>
              <Text style={[styles.brand, item?.uuid && { opacity: 0.5 }]}>
                {(item && item.advertiser) || 'Onbekend'}
              </Text>
              <Text style={[styles.name, item?.uuid && { opacity: 0.5 }]}>
                {item.title}
              </Text>
              <Text style={[styles.sku, item?.uuid && { opacity: 0.4 }]}>
                {getFriendlyDate(
                  fromTimestamp(
                    item?.uuid
                      ? (item?.date || new Date().getTime()) / 1000
                      : item?.date || 0
                  )
                )}
              </Text>
            </>
          </View>
        </View>
      </TouchableRipple>
      <Divider
        style={{ backgroundColor: '#000' }}
        key={item.url + '_divider'}
      />
    </>
  )
}

export default React.memo(ListItem)
