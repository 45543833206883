import { useEffect } from 'react'
// @ts-ignore
import { logout } from 'react-auth/lib/actions/authentication'

function Logout() {
  useEffect(() => {
    logout()
  }, [])
  return null
}
export default Logout
