import React, { useEffect, useRef, useState } from 'react'
import {
  Image,
  NativeSyntheticEvent,
  ScrollView,
  StyleSheet,
  TextInputChangeEventData,
  View,
} from 'react-native'

import { Button, HelperText, TextInput } from 'react-native-paper'
// @ts-ignore
import changePasswordHOC from 'react-auth/lib/hocs/changePasswordHOC'
import base64 from 'base-64'
import ErrorMessages from '../components/ErrorMessages'
// @ts-ignore
import { pushHome } from '../Navigation'
import { connect } from 'react-redux'
import { Form, useFormState } from 'react-native-use-form'
import logo from '../vectors/logo-color.png'
import { isNotEmpty, looksLikeMail } from './Login'

const styles = StyleSheet.create({
  loginContainer: {
    textAlign: 'center',

    display: 'flex',
    flex: 1,
    // minHeight: 100 + 'vh',

    backgroundColor: '#FFF',

    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    marginTop: 15,
    marginBottom: 15,
  },
  // overlay: {
  //   // backgroundColor: 'rgba(0,0,0,0.3)',
  //   height: '100%',
  //   width: '100%',
  //   top: 0,
  // },
  link: {
    color: '#8e8e8e',
    textAlign: 'right',
    // justifyItems: 'flex-end',
    // float: 'right',
    // width: '100%',
  },
  card: {
    width: 400,
    flexDirection: 'column',
    // @ts-ignore
    justifyContent: 'center',
    alignItems: 'center',
    // textAlign: 'center',
    minHeight: 100,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    borderRadius: 4,
  },
  input: {
    width: '100%',
    marginBottom: 8,
    marginTop: 8,
    // fontWeight: 600,
  },
  cardContent: {
    // paddingBottom:,
    padding: 20,
    width: '100%',
  },
  buttonRoot: {
    padding: 8,
    // paddingRight: 8,
    marginTop: 20,
    // marginBottom: -110,
  },
  buttonIcon: {
    marginLeft: 8,
  },
  progress: {
    marginLeft: 8,
  },
  buttonLabel: {
    textTransform: 'none',
    // fontSize: 17,
    // fontWeight: 600,
    color: '#fff',
    // color: '#005490',
  },
  snackbar: {
    // minWidth: '100%!important',
    margin: 0,
    // boxSizing: 'border-box',
  },
})

export function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

type ChangeScreenPropsType = {
  match: any
  secret: string
  onChangeField: (key: string, value: any) => any
  onLogin: ({ login, password }: { login: string; password: string }) => any
  completed: boolean
  values: {
    email: string
    password: string
    passwordCheck: string
    secret: string
  }
  onSubmit: () => any
  sending: boolean
  error: any
  authenticated: boolean
}

// type ErrorType = { name: string; message: string }
function ChangeScreen(props: ChangeScreenPropsType) {
  const {
    match,
    secret,
    onChangeField,
    completed,
    onLogin,
    values,
    onSubmit,
    sending,
    error,
    authenticated,
  }: ChangeScreenPropsType = props
  const [
    { errors, submit, formProps, hasError, setError, setField, ...rest },
    { email, password },
  ] = useFormState(
    {
      email: values.email,
      password: values.password,
      passwordCheck: values.passwordCheck,
      secret: values.secret,
    },
    {
      onSubmit: ({ email, password, passwordCheck }) => {
        if (!email) {
          setError('email', 'Controleer uw E-mailadres')
        }
        if (password !== passwordCheck) {
          setError('password', 'Wachtwoorden komen niet overeen')
          setError('passwordCheck', 'Wachtwoorden komen niet overeen')
        }
        if (
          isNotEmpty(password) &&
          isNotEmpty(passwordCheck) &&
          isNotEmpty(email) &&
          password === passwordCheck
        ) {
          onSubmit()
        }
      },
    }
  )

  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (!(rest && rest.values && rest.values.email)) {
      const encodedSecret = match?.params?.secret || secret

      if (encodedSecret) {
        const decodedSecret = base64.decode(encodedSecret)
        const re = new RegExp('(.*)-(.[0-9]*)-(.*)')
        const matches = re.exec(decodedSecret)

        const email = matches ? matches[1] : ''
        const secret = matches ? matches[3] : ''

        onChangeField('email', email)
        onChangeField('secret', secret)
        setField('email', email)
        setField('secret', secret)
      }
    }
  }, [match, onChangeField, secret, setField, rest])

  const oldCompleted = usePrevious(completed)
  const oldAuthenticated = usePrevious(authenticated)

  useEffect(() => {
    if (completed !== oldCompleted) {
      onLogin({
        login: values.email,
        password: values.password,
      })
    }
  }, [onLogin, completed, oldCompleted, values])

  useEffect(() => {
    if (authenticated !== oldAuthenticated && completed) {
      pushHome(props)
    }
  }, [authenticated, oldAuthenticated, props, completed])

  const change = (
    key: string,
    e: NativeSyntheticEvent<TextInputChangeEventData>
  ) => {
    const element = e.target as unknown as HTMLInputElement
    const value = element.value
    // TODO: implement clearErrors function here
    onChangeField(key, value)
  }

  return [
    <ScrollView
      contentContainerStyle={styles.loginContainer}
      keyboardShouldPersistTaps={'handled'}
      scrollEnabled={true}
      key={'content'}
    >
      <View style={styles.card}>
        <Image
          style={{
            alignSelf: 'center',
            marginTop: 15,
            marginBottom: 15,
            width: 200,
            height: 45,
          }}
          source={{ uri: logo }}
        />
        {error && (
          <ErrorMessages
            messages={[
              'Er is iets mis gegaan. Neem contact op met support@profects.com',
            ].filter((n) => !!n)}
          />
        )}

        <View style={styles.cardContent}>
          <Form {...formProps}>
            <TextInput
              mode="outlined"
              error={hasError('email')}
              {...email('email', {
                validate: (v: string) => {
                  if (!isNotEmpty(v)) {
                    return 'E-mailadres is verplicht'
                  } else if (!looksLikeMail(v)) {
                    return 'Controleer uw E-mailadres'
                  }
                  return true
                },
              })}
              label={'E-mailadres'}
              onChange={(e) => change('login', e)}
              disabled
            />
            <HelperText type="error" visible={hasError('email')}>
              {errors.email}
            </HelperText>

            <TextInput
              mode="outlined"
              {...password('password', {
                required: true,
                minLength: 3,
                validate: (v: string) => {
                  return isNotEmpty(v) ? true : 'Wachtwoord is verplicht'
                },
              })}
              label={'Wachtwoord'}
              error={hasError('password')}
              secureTextEntry={!showPassword}
              onChange={(e) => change('password', e)}
              right={
                <TextInput.Icon
                  name={showPassword ? 'eye-off' : 'eye'}
                  onPress={() => setShowPassword(!showPassword)}
                />
              }
            />
            <HelperText type="error" visible={hasError('password')}>
              {errors.password}
            </HelperText>

            <TextInput
              mode="outlined"
              {...password('passwordCheck', {
                required: true,
                minLength: 3,
                validate: (v: string) => {
                  return isNotEmpty(v)
                    ? true
                    : 'Herhaal wachtwoord is verplicht'
                },
              })}
              label={'Herhaal wachtwoord is verplicht'}
              error={hasError('passwordCheck')}
              secureTextEntry={!showPassword}
              onChange={(e) => change('passwordCheck', e)}
              right={
                <TextInput.Icon
                  name={showPassword ? 'eye-off' : 'eye'}
                  onPress={() => setShowPassword(!showPassword)}
                />
              }
            />
            <HelperText type="error" visible={hasError('passwordCheck')}>
              {errors.passwordCheck}
            </HelperText>
            {/*@ts-ignore*/}
            <Button mode="contained" onPress={submit} loading={sending}>
              {sending ? 'Wachtwoord wijzigen...' : 'Wachtwoord wijzigen'}
            </Button>

            <HelperText
              type="error"
              visible={
                error !== undefined &&
                error !== null &&
                error !== '' &&
                typeof error === 'string'
              }
            >
              {`${error}`}
            </HelperText>
          </Form>
        </View>
      </View>
    </ScrollView>,
  ]
}

const mapStateToProps = (state: any) => ({
  authenticated: state.auth.authenticated,
})

// @ts-ignore
export default changePasswordHOC(connect(mapStateToProps)(ChangeScreen))
