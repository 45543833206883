import React, { Component } from 'react'
import {
  Animated,
  Button,
  Dimensions,
  FlatList,
  Platform,
  StyleSheet,
  View,
} from 'react-native'
import { ActivityIndicator, Surface, Text } from 'react-native-paper'
import { v4 as uuidv4 } from 'uuid'

import PT from 'prop-types'
import { debounce, groupBy } from 'lodash'
import ErrorMessages from '../components/ErrorMessages'
import DetailRowShimmer from '../components/Item.shimmer'
import { getStatusBarHeight } from 'react-native-iphone-x-helper'

import widthAndHeightHOC from '../WidthAndHeight/widthAndHeightHOC'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'
import ScrollViewWithSaving from './ScrollViewWithSaving'
import Empty from '../components/Empty'

const STATUS_BAR_HEIGHT = getStatusBarHeight(true) + 4 || 0

const NAVBAR_HEIGHT = STATUS_BAR_HEIGHT
const getPaddingTopNavbar = () =>
  // withStatusBar
  {
    return 0
  }

const styles = StyleSheet.create({
  list: {
    flex: 1,
  },
  footer: {
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },

  fab: {
    position: 'absolute',
    borderRadius: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },

    elevation: 5,

    shadowOpacity: 0.05,
    shadowRadius: 4.65,
    // backgroundColor: theme.palette.primary.main,
  },

  fabPusher: {
    paddingBottom: 16 + 36 + 16,
  },

  animatedHeader: {
    position: 'absolute',
    left: 0,
    // width: Platform.OS === 'web' ? '100%' : undefined,
    // maxWidth: Platform.OS === 'web' ? 600 : undefined,

    top: 0,
    right: 0,
    zIndex: 10,
    // opacity: navbarOpacity,
    // backgroundColor: 'pink',

    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 6,
    paddingBottom: 0,
    // paddingBottom: 0,
    // elevation: 3,
  },
  animatedHeaderWithStatusBar: {
    paddingTop: 6 + STATUS_BAR_HEIGHT,
  },
  statusBar: {
    // position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    // elevation: 10,

    top: 0,
    left: 0,
    right: 0,
    zIndex: 40,
    height: STATUS_BAR_HEIGHT || 0,
  },
  emptyTitle: { fontSize: 19, fontWeight: 'bold' },
  emptyDescription: { color: '#525252', marginTop: 6 },
  emptyImage: { width: 130, height: 90, marginBottom: 56 },
  emptyContainer: {
    flex: 1,

    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    paddingTop: 56,
    paddingBottom: 56,
  },
})

const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
  const paddingToBottom = 20
  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  )
}

const hasFiltersInParam = (params = {}) => {
  // alert(JSON.stringify(Object.keys(params)))
  // console.log('hasFiltersInParam')
  return (
    Object.keys(params).filter(
      (key) =>
        key !== 'order' &&
        key !== 'limit' &&
        key !== 'offset' &&
        key !== 'page' &&
        key !== 'number' &&
        !!params[key]
    ).length > 0
  )
}

class GeneralList extends Component {
  _showingStatusBar = true
  _clampedScrollValue = 0
  _offsetValue = 0
  _scrollValue = 0

  constructor(props) {
    super(props)

    const scrollAnim = new Animated.Value(0)
    const offsetAnim = new Animated.Value(0)
    this.debouncedSearch = debounce(this._search, 250)

    this.state = {
      scrollAnim,
      offsetAnim,
      lastItemIdScrolledTo: undefined,
      listKey: `${uuidv4()}`,
      clampedScroll: Animated.diffClamp(
        Animated.add(
          scrollAnim.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 1],
            extrapolateLeft: 'clamp',
          }),
          offsetAnim
        ),
        0,
        NAVBAR_HEIGHT - 0
      ),
    }
    this._animatedEvent =
      Platform.OS !== 'web'
        ? Animated.event(
            [{ nativeEvent: { contentOffset: { y: this.state.scrollAnim } } }],
            { useNativeDriver: true }
          )
        : null
  }

  UNSAFE_componentWillMount() {
    if (
      (this.props.loadListOnMount && !this.props.list.loadedList) ||
      this.props.reloadListOnMount
    ) {
      console.log('Reloading list')
      this.props.loadList(
        { params: this.props.getParams(this.props) },
        { silentReloading: true }
      )
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (
      this.props.list &&
      this.props.list.data &&
      this.props.list.data.length > 0 &&
      this.props.itemId &&
      this.props.itemId !== this.state.lastItemIdScrolledTo
    ) {
      const index = this.props.list.data.findIndex((it) =>
        it.url.includes(this.props.itemId)
      )
      if (index > -1) {
        this.scrollToIndex(index)
      }
    }
  }

  componentDidMount() {
    this.state.scrollAnim.addListener(({ value }) => {
      const diff = value - this._scrollValue
      this._scrollValue = value
      this._clampedScrollValue = Math.min(
        Math.max(this._clampedScrollValue + diff, 0),
        NAVBAR_HEIGHT - 0
      )
    })
    this.state.offsetAnim.addListener(({ value }) => {
      this._offsetValue = value
    })
  }

  componentWillUnmount() {
    this.state.scrollAnim.removeAllListeners()
    this.state.offsetAnim.removeAllListeners()
  }

  // _debouncedLoadMore = debounce(
  //   () => {
  //     this._loadMore()
  //   },
  //   1200,
  //   { leading: true }
  // )
  _handleScroll = ({ nativeEvent }) => {
    const nearBottom = isCloseToBottom(nativeEvent)
    // console.log(nearBottom, 'NEAR BOTTOM???')
    if (nearBottom) {
      this._loadMore()
    }
  }

  _loadMore = () => {
    // console.log(this.props.list.stopLoadingMore)
    // console.log(this.props.list.loadingMoreList)
    // console.log('SHOULD BOTH BE FALSE')
    if (
      !this.props.list.stopLoadingMore &&
      !this.props.list.loadingMoreList &&
      this.props.loadMoreList
    ) {
      // console.log('LOAD MORE!')
      this.props.loadMoreList()
    }
  }
  _onScrollEndDrag = () => {
    this._scrollEndTimer = setTimeout(this._onMomentumScrollEnd, 250)
  }

  _onMomentumScrollBegin = () => {
    clearTimeout(this._scrollEndTimer)
  }

  _onMomentumScrollEnd = () => {
    const toValue =
      this._scrollValue > NAVBAR_HEIGHT &&
      this._clampedScrollValue > (NAVBAR_HEIGHT - 0) / 2
        ? this._offsetValue + NAVBAR_HEIGHT
        : this._offsetValue - NAVBAR_HEIGHT

    Animated.timing(this.state.offsetAnim, {
      toValue,
      duration: 350,
      useNativeDriver: true,
    }).start()
  }
  _search = (value) => {
    this.props.loadList(
      { params: this.props.getParams(this.props, value) },
      { silentReloading: false }
    )
  }

  _renderEmptyState = () => {
    const search = this.props.list.params && this.props.list.params.search
    const isReallyEmpty = !hasFiltersInParam(
      this.props.getParams(this.props, search)
    )
    // console.log(this.props.list.params)
    if (this.props.renderEmptyState) {
      return this.props.renderEmptyState()
    }

    return (
      <View
        key="empty"
        style={[
          styles.emptyContainer,
          { minHeight: Dimensions.get('window').height - 200 },
        ]}
      >
        <Text style={styles.emptyTitle}>
          {isReallyEmpty
            ? 'Een schone start'
            : `Geen resultaat ${search ? 'voor: ' + search : ''}`}
        </Text>
        <Text style={styles.emptyDescription}>
          {isReallyEmpty
            ? 'Nog niets aangemaakt'
            : `Probeer een ander${search ? 'e zoekterm' : ' filter'}`}
        </Text>
      </View>
    )
  }
  _showStatusBar = () => {
    const { withStatusBar, width, height } = this.props
    const isLandscape = width > height
    let showStatusbar = withStatusBar
    if (withStatusBar && Platform.OS === 'ios' && isLandscape) {
      showStatusbar = false
    }
    return showStatusbar
  }
  _renderListHeader = () => {
    if (this.props.renderListHeader) {
      return this.props.renderListHeader()
    }
    const {
      // searchPlaceHolder,
      // searchIcon,
      // onSearchIconPress,
      // isSelecting,
      // focusSearch,
      renderBelowSearchBar,
    } = this.props

    // const navbarTranslate = clampedScroll.interpolate({
    //   inputRange: [0, NAVBAR_HEIGHT - 0],
    //   outputRange: [0, -(NAVBAR_HEIGHT - 0)],
    //   extrapolate: 'clamp',
    // })

    // const navbarOpacity = clampedScroll.interpolate({
    //   inputRange: [0, NAVBAR_HEIGHT - 0],
    //   outputRange: [1, 0],
    //   extrapolate: 'clamp',
    // })

    // const showStatusbar = this._showStatusBar()
    // const searchValue = this.props.list.params && this.props.list.params.search

    return (
      <View style={{ marginBottom: 0 }} key={'listheader'}>
        {/*{showStatusbar && <View style={styles.statusBar} />}*/}
        {/*{!isSelecting && (*/}
        {/*  <SearchHeader*/}
        {/*    searchIcon={searchIcon}*/}
        {/*    onIconPress={onSearchIconPress}*/}
        {/*    searchPlaceHolder={searchPlaceHolder}*/}
        {/*    onSearch={this.debouncedSearch}*/}
        {/*    defaultValue={searchValue}*/}
        {/*    autoFocusSearch={focusSearch}*/}
        {/*  />*/}
        {/*)}*/}
        {renderBelowSearchBar && renderBelowSearchBar()}
      </View>
    )
  }

  _renderShimmer = (key) => {
    if (this.props.renderShimmer) {
      return this.props.renderShimmer(key)
    }
    const { icon } = this.props
    return <DetailRowShimmer key={`${key}`} icon={icon} />
  }
  _renderHeader = () => {
    const { renderAboveHeader } = this.props
    const {
      data,
      loadingListError,
      loadingListErrorMessage,
      loadingList,
      loadedList,
    } = this.props.list

    const showStatusbar = this._showStatusBar()
    return (
      <View>
        {!this.props.renderListHeader ? (
          <View
            style={{
              paddingTop: getPaddingTopNavbar(showStatusbar),
            }}
          />
        ) : null}
        {renderAboveHeader ? renderAboveHeader() : null}
        {loadingListError ? (
          <ErrorMessages errorMessages={[loadingListErrorMessage]} />
        ) : null}
        {loadingList && !loadedList ? <ActivityIndicator /> : null}
        {loadedList &&
          !loadingList &&
          data.length === 0 &&
          !loadingListError &&
          this._renderEmptyState()}
      </View>
    )
  }
  _renderFooter = () => {
    const { loadingMoreList } = this.props.list
    const { listKey } = this.state
    return (
      <View key={`footer-${listKey}`}>
        {loadingMoreList
          ? [1, 2, 3, 4, 5].map((l) => this._renderShimmer(l))
          : null}
        {this.props.onPressAdd ? <View style={styles.fabPusher} /> : null}
        {this.props.renderBelowFooter ? this.props.renderBelowFooter() : null}
      </View>
    )
  }
  _keyExtractor = (item) =>
    `id_${this.state.listKey}_${
      item?.id ? item.id : item?.uuid ? item.uuid : item.url ? item.url : ''
    }_${this.props.list && this.props.list.data && this.props.list.data.length}`

  scrollToIndex = (index) => {
    this.setState({
      lastItemIdScrolledTo: this.props.itemId,
    })
    this.flatListRef.scrollToIndex({ animated: true, index: index })
  }
  getItemLayout = (data, index) => ({
    length: 450,
    offset: 450 * index,
    index,
  })

  render() {
    const {
      rerenderKey,
      safePadding,
      safe,
      // disableSafe,
      groupByProp,
    } = this.props
    const { reloadingList, loadingMoreList, loadingList, data } =
      this.props.list

    const showStatusbar = this._showStatusBar()

    const FlatListComponent =
      Platform.OS === 'web' ? FlatList : Animated.FlatList
    return [
      this._renderListHeader(),
      Platform.OS === 'web' ? (
        <ScrollViewWithSaving
          key={`container-${this.state.listKey}`}
          onScroll={this._handleScroll}
          scrollEventThrottle={400}
        >
          {this._renderHeader()}
          {(groupByProp ? Object.keys(groupBy(data, groupByProp)) : data)
            .length > 0 ? (
            (groupByProp ? Object.keys(groupBy(data, groupByProp)) : data).map(
              (item, index) => {
                if (groupByProp) {
                  const it = groupBy(data, groupByProp)[item]
                  return (
                    <View key={this._keyExtractor(it[0])}>
                      {this.props.renderItem(it)}
                    </View>
                  )
                }
                return (
                  <View key={this._keyExtractor(item)}>
                    {this.props.renderItem({ item, index })}
                  </View>
                )
              }
            )
          ) : (
            <Empty
              title={'Niks gevonden'}
              description={'Probeer andere filters'}
            />
          )}
          {this._renderFooter()}
        </ScrollViewWithSaving>
      ) : (
        <FlatListComponent
          key="flatlist"
          ref={(ref) => {
            this.flatListRef = ref
          }}
          getItemLayout={this.getItemLayout}
          contentContainerStyle={safePadding}
          contentInsetAdjustmentBehavior={'never'}
          listKey={this.state.listKey}
          data={data}
          renderItem={this.props.renderItem}
          refreshing={reloadingList}
          onRefresh={() =>
            this.props.reloadList({
              params: this.props.getParams(this.props),
            })
          }
          ListFooterComponent={this._renderFooter}
          ListHeaderComponent={this._renderHeader}
          keyExtractor={this._keyExtractor}
          extraData={`${loadingList}_${loadingMoreList}_${rerenderKey}`}
          progressViewOffset={getPaddingTopNavbar(showStatusbar)}
          scrollEventThrottle={1}
          onMomentumScrollBegin={this._onMomentumScrollBegin}
          onMomentumScrollEnd={this._onMomentumScrollEnd}
          onScrollEndDrag={this._onScrollEndDrag}
          onScroll={this._animatedEvent}
          onEndReached={this._loadMore}
          onScrollToIndexFailed={this.scrollToIndexFailed}
          onEndReachedThreshold={1000}
          ListEmptyComponent={() => (
            <Empty
              title={'Niks gevonden'}
              description={'Probeer andere filters'}
            />
          )}
        />
      ),
      this.props.onPressAdd && (
        <Surface
          style={[
            styles.fab,
            { bottom: 16 + safe.bottom, right: 16 + safe.right },
            { backgroundColor: this.props.fabColor },
          ]}
        >
          <Button
            key="add"
            testID={'addButton'}
            style={[
              styles.fab,
              { bottom: 16 + safe.bottom, right: 16 + safe.right },
              { backgroundColor: this.props.fabColor },
            ]}
            // fabStyle={{ backgroundColor: this.props.fabColor }}
            color="#fff"
            icon="plus"
            onPress={this.props.onPressAdd}
            uppercase={false}
            dark
          >
            <Text style={{ color: '#FFF' }}>
              {this.props.addLabel ? this.props.addLabel : 'Nieuw'}
            </Text>
          </Button>
        </Surface>
      ),
    ]
  }
}

GeneralList.propTypes = {
  componentId: Platform.OS === 'web' ? PT.string : PT.string.isRequired,
  onPressAdd: PT.func,
  renderItem: PT.func.isRequired,
  icon: PT.bool,
}
GeneralList.defaultProps = {
  getParams: (listProps, search) => {
    const { order } = listProps
    return { order, search, [`search[language]`]: 'nl' }
  },
  reloadList: () => {
    console.log('reload list default')
  },
  loadListOnMount: true,
  withStatusBar: true,
  icon: false,
  order: '-created_at',
}

export default safeAreaHOC(widthAndHeightHOC(GeneralList))
