import * as a from './actions'
import { ReducerInitialStateType } from '../WidthAndHeight/reducer'

const initialState = {
  messages: [],
}

const reducer = (
  state = initialState,
  { type, error, payload }: ReducerInitialStateType
) => {
  switch (type) {
    case a.SET_MESSAGES: {
      return {
        ...state,
        messages: payload || [],
      }
    }
    default:
      return state
  }
}

export default reducer
