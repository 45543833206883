import { all, call } from 'redux-saga/effects'
// @ts-ignore
import auth from 'react-auth/lib/sagas/authentication'
// @ts-ignore
import register from 'react-auth/lib/sagas/register'
// @ts-ignore
import forgotPassword from 'react-auth/lib/sagas/forgotPassword'
import user from './Auth/User/saga'
import home from './Home/saga'

export default function* rootSaga() {
  yield all([
    call(auth),
    call(register),
    call(forgotPassword),
    call(home),
    call(user),
  ])
}
