import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

export function switchMenu(path: string) {
  history.push(path)
}

// Fix strange race effect on mobile devices
// with touch event vs onClick...
let lastTime = 0

export function openMenu() {
  lastTime = new Date().getTime()
  const sideContainer = document.getElementsByClassName('side-container')

  if (sideContainer && sideContainer[0]) {
    sideContainer[0].classList.add('opened')
  }
}

export function closeMenu() {
  const now = new Date().getTime()
  // Fix strange race effect
  if (now - lastTime > 400) {
    const sideContainer = document.getElementsByClassName('side-container')

    if (sideContainer && sideContainer[0]) {
      sideContainer[0].classList.remove('opened')
    }
  }
}

export function push(componentId: string, options: any) {
  if (options.component.passProps) {
    let name = options.component.name
    if (!name.endsWith('/')) {
      name += '/'
    }
    name += Object.keys(options.component.passProps)
      .filter((key) => key !== 'fromComponent')
      .map((key) => options.component.passProps[key])
      .join('/')

    history.push(name)
    return
  }
  history.push(options.component.name)
}

export function pop() {
  history.goBack()
}

export const getBottomTabs = () => {}

export function mergeOptions(componentId: string, options = {}) {
  //
}

export const openScreen = (
  props: any,
  navigateTo: string,
  redirectUrl?: string
) => {
  if (navigateTo && props.location.pathname !== `/${navigateTo}`) {
    props.history.replace(`/${navigateTo}`)
    return
  }
  props.history.replace('/')
}

export function pushHome(props: any) {
  const params = new URLSearchParams(props.location.search)
  const redirectUrl = params.get('redirectUrl') || '/'
  if (redirectUrl.includes('http')) {
    window.location.assign(redirectUrl)
  }
  openScreen(props, redirectUrl.substr(1))
}

export function pushAuth(props: any) {
  const redirectUrl = props.location.pathname
  if (redirectUrl.includes('logout')) {
    openScreen(props, `auth`)
  } else {
    openScreen(props, `auth?redirectUrl=${redirectUrl}`)
  }
}

export function setHome(props: any) {
  const params = new URLSearchParams(props.location.search)
  const redirectUrl = params.get('redirectUrl') || '/'
  console.log(props)
  openScreen(props, redirectUrl.substr(1))
}

// let locale = 'en'
export const setLocaleNavigation = (l: string) => {
  // locale = l
}

const navi = {
  pop: () => {
    history.goBack()
  },
  popTo: () => {},
  popToRoot: () => {
    history.push('/')
  },
  events: () => {
    // Fake native implementation
    // eslint-disable-next-line
    registerBottomTabSelectedListener: () => {
      // eslint-disable-next-line
      remove: () => {}
    }
  },
}
export default navi
