import * as a from './actions'
import { Dimensions } from 'react-native'
const { width, height } = Dimensions.get('window')
const initialState = {
  width,
  height,
}

export type ReducerInitialStateType = {
  type: string
  error?: any
  payload?: any
}

const reducer = (
  state = initialState,
  { type, error, payload }: ReducerInitialStateType
) => {
  switch (type) {
    case a.UPDATE: {
      if (payload.width !== state.width || payload.height !== state.height) {
        return {
          width: payload.width,
          height: payload.height,
        }
      }
      return state
    }
    default:
      return state
  }
}

export default reducer
