import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
const styles = StyleSheet.create({
  resizer: {
    maxWidth: 1200,
    width: '100%',
    alignSelf: 'center',
    // flexDirection: 'column',
  },
})

class Resizer extends Component<any> {
  render() {
    return (
      <View style={[styles.resizer, this.props.style]}>
        {this.props.children}
      </View>
    )
  }
}
export default Resizer
