import React, { ReactElement, useEffect, useRef } from 'react'
// @ts-ignore
import { Form, useFormState } from 'react-native-use-form'

// @ts-ignore
import loginHOC from 'react-auth/lib/hocs/loginHOC'
import logo from '../vectors/logo-color.png'
import {
  Image,
  ImageSourcePropType,
  NativeSyntheticEvent,
  Pressable,
  ScrollView,
  StyleSheet,
  TextInputChangeEventData,
  View,
} from 'react-native'
import {
  Button,
  HelperText,
  Text,
  TextInput,
  useTheme,
} from 'react-native-paper'
import { push } from '../Navigation'
import { FORGOT_PASSWORD_SCREEN_ROUTE } from '../Routes.config'

export function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
const styles = StyleSheet.create({
  loginContainer: {
    textAlign: 'center',

    display: 'flex',
    flex: 1,
    // minHeight: 100 + 'vh',

    backgroundColor: '#FFF',

    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    marginTop: 15,
    marginBottom: 15,
  },
  // overlay: {
  //   // backgroundColor: 'rgba(0,0,0,0.3)',
  //   height: '100%',
  //   width: '100%',
  //   top: 0,
  // },
  link: {
    color: '#8e8e8e',
    textAlign: 'right',
    // justifyItems: 'flex-end',
    // float: 'right',
    // width: '100%',
  },
  card: {
    width: 400,
    flexDirection: 'column',
    // @ts-ignore
    justifyContent: 'center',
    alignItems: 'center',
    // textAlign: 'center',
    minHeight: 100,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    borderRadius: 4,
  },
  input: {
    width: '100%',
    marginBottom: 8,
    marginTop: 8,
    // fontWeight: 600,
  },
  cardContent: {
    // paddingBottom:,
    padding: 20,
    width: '100%',
  },
  buttonRoot: {
    padding: 8,
    // paddingRight: 8,
    marginTop: 20,
    // marginBottom: -110,
  },
  buttonIcon: {
    marginLeft: 8,
  },
  progress: {
    marginLeft: 8,
  },
  buttonLabel: {
    textTransform: 'none',
    // fontSize: 17,
    // fontWeight: 600,
    color: '#fff',
    // color: '#005490',
  },
  snackbar: {
    // minWidth: '100%!important',
    margin: 0,
    // boxSizing: 'border-box',
  },
})
type ThemedLoginPropsType = {
  sending: boolean
  error: any
  onSubmit: () => any
  onChangeField: (key: string, value: any) => any
  values: {
    email: string
    password: string
  }
  componentId: any
}
export type PressableState = Readonly<{
  pressed: boolean
  hovered?: boolean
  focused?: boolean
}>
function ThemedLogin({
  sending,
  error,
  onSubmit,
  values,
  onChangeField,
  componentId,
}: ThemedLoginPropsType) {
  const [{ errors, submit, formProps, hasError }, { email, password }] =
    useFormState(
      {
        email: values.email,
        password: values.password,
      },
      {
        onSubmit: () => {
          onSubmit()
        },
      }
    )
  const [showPassword, setShowPassword] = React.useState(false)

  const change = (
    key: string,
    e: NativeSyntheticEvent<TextInputChangeEventData>
  ) => {
    const value = e.nativeEvent.text
    onChangeField(key, value)
  }
  const theme = useTheme()
  return (
    <ScrollView
      contentContainerStyle={styles.loginContainer}
      keyboardShouldPersistTaps={'handled'}
      scrollEnabled={true}
    >
      <View style={styles.card}>
        <Image
          style={{
            alignSelf: 'center',
            marginTop: 15,
            marginBottom: 15,
            width: 200,
            height: 45,
          }}
          source={logo as ImageSourcePropType}
        />
        <View style={styles.cardContent}>
          <Form {...formProps}>
            <TextInput
              mode="flat"
              error={hasError('email')}
              {...email('email', {
                validate: (v: string) => {
                  if (!isNotEmpty(v)) {
                    return 'E-mailadres is verplicht'
                  } else if (!looksLikeMail(v)) {
                    return 'Controleer uw E-mailadres'
                  }
                  return true
                },
              })}
              label={'E-mailadres'}
              onChange={(e) => change('login', e)}
            />
            <HelperText type="error" visible={hasError('email')}>
              {errors.email}
            </HelperText>

            <TextInput
              mode="flat"
              {...password('password', {
                required: true,
                minLength: 3,
                validate: (v: string) => {
                  return isNotEmpty(v) ? true : 'Wachtwoord is verplicht'
                },
              })}
              label={'Wachtwoord'}
              error={hasError('password')}
              secureTextEntry={!showPassword}
              onChange={(e) => change('password', e)}
              right={
                <TextInput.Icon
                  name={showPassword ? 'eye-off' : 'eye'}
                  onPress={() => setShowPassword(!showPassword)}
                />
              }
            />
            <View style={{ flexDirection: 'row', marginBottom: 20 }}>
              <HelperText
                type="error"
                visible={hasError('password')}
                style={{ flex: 1 }}
              >
                {errors.password}
              </HelperText>
              <View style={{ padding: 4 }}>
                <Pressable>
                  {({ hovered }: PressableState): ReactElement => (
                    <Text
                      style={{
                        color: theme.colors.accent,
                        textDecorationLine: hovered ? 'underline' : 'none',
                      }}
                      onPress={() => {
                        push(componentId, {
                          component: { name: FORGOT_PASSWORD_SCREEN_ROUTE },
                        })
                      }}
                    >
                      {'Wachtwoord vergeten?'}
                    </Text>
                  )}
                </Pressable>
              </View>
            </View>

            <Button mode="contained" onPress={submit} loading={sending}>
              {sending ? 'Aan het inloggen...' : 'Inloggen'}
            </Button>

            <HelperText
              type="error"
              visible={
                error !== undefined &&
                error !== null &&
                error !== '' &&
                typeof error === 'string'
              }
            >
              {`${'Controleer uw E-mailadres en Wachtwoord'}`}
            </HelperText>
          </Form>
        </View>
      </View>
    </ScrollView>
  )
}
export function looksLikeMail(str: string): boolean {
  let lastAtPos = str.lastIndexOf('@')
  let lastDotPos = str.lastIndexOf('.')
  return (
    lastAtPos < lastDotPos &&
    lastAtPos > 0 &&
    str.indexOf('@@') === -1 &&
    lastDotPos > 2 &&
    str.length - lastDotPos > 2
  )
}
export function isNotEmpty(str: string): boolean {
  return str !== '' && str !== null && str !== undefined
}

export default loginHOC(ThemedLogin, [{ name: 'login' }, { name: 'password' }])
