import { Reducer } from 'redux'

type ReducerType<T> = {
  object: T | undefined | null
  newObject: T | undefined | null

  loading: boolean
  loadingError: boolean

  creating: boolean
  creatingError: boolean
  errorMessage?: string

  updating: boolean
  updatingError: boolean

  removing: boolean
  removingError: boolean
  removed: boolean
}

function getInitialState<T>(): ReducerType<T> {
  return {
    object: null,
    newObject: null,

    loading: false,
    loadingError: false,

    creating: false,
    creatingError: false,
    errorMessage: '',

    updating: false,
    updatingError: false,

    removing: false,
    removingError: false,
    removed: false,
  }
}

function crudReducer<T>(actionTypes: any): Reducer<ReducerType<T>, any> {
  return function (
    state = getInitialState<T>(),
    { type, payload, error }: any
  ): ReducerType<T> {
    switch (type) {
      case 'RESET': {
        return getInitialState<T>()
      }
      case actionTypes.load:
        return {
          ...getInitialState<T>(),
          loading: true,
          loadingError: false,
          object: Number.isInteger(payload) ? { id: payload } : payload,
        }

      case actionTypes.setData:
        return payload.isNew
          ? ({
            ...state,
            newObject: {
              ...state.newObject,
              [payload.key]: payload.value,
            },
          } as any)
          : {
            ...state,
            object: {
              ...state.object,
              [payload.key]: payload.value,
            },
          }
      case actionTypes.loaded:
        return error
          ? {
            ...state,
            loading: false,
            loadingError: true,
          }
          : {
            ...state,
            loading: false,
            loadingError: false,
            object: payload || {},
          }
      case actionTypes.create:
        return {
          ...state,
          newObject: payload || state.newObject,
          creatingError: false,
          creating: true,
        }
      case actionTypes.created:
        return error
          ? {
            ...state,
            newObject: payload || state.newObject,
            creatingError: true,
            creating: false,
            errorMessage: payload.message,
          }
          : {
            ...state,
            newObject: payload || state.newObject,
            creating: false,
            creatingError: false,
          }
      case actionTypes.update:
        return {
          ...state,
          object: payload || state.object,
          updatingError: false,
          updating: true,
        }
      case actionTypes.updated:
        return error
          ? {
            ...state,
            object: undefined,
            updatingError: true,
            updating: false,
            errorMessage: payload.message,
          }
          : {
            ...state,
            object: payload,
            updatingError: false,
            updating: false,
          }
      case actionTypes.remove:
        return {
          ...state,
          object: payload || state.object,
          removingError: false,
          removing: true,
          removed: false,
        }
      case actionTypes.removed:
        return error
          ? {
            ...state,
            object: undefined,
            removingError: true,
            removing: false,
            removed: false,
          }
          : {
            ...state,
            removingError: false,
            removing: false,
            removed: true,
          }

      default:
        return state
    }
  }
}

export default crudReducer
