import {getErrorMessage} from '../api'
// import { uniqBy } from 'lodash'
// CRUD
const initialCrudState = {
  object: undefined,
  savedState: true,
  savingState: false,
  savingStateError: false,
  loaded: false,

  loading: false,
  updating: false,
  updated: false,
  removing: false,
  creating: false,
  created: false,

  loadingError: false,
  loadingErrorMessage: '',
  updatingError: false,
  updatingErrorMessage: '',
  removingError: false,
  removingErrorMessage: '',
  creatingError: false,
  creatingErrorMessage: '',
}
// PAGED LIST
const initialListState = {
  tree: [],
  data: [],
  params: undefined,

  reloadingList: false,
  loadingList: false,
  loadingMoreList: false,
  loadedList: false,

  offset: 0,
  total: 0,

  stopLoadingMore: false,

  loadingListError: false,
  loadingListErrorMessage: '',

  loadingMoreListError: false,
  loadingMoreListErrorMessage: '',
}
const initialSelectedState = []

const defaultState = {
  crud: initialCrudState,
  list: initialListState,
  download: {
    id: null,
    response: null,
    downloading: false,
    downloaded: false,
    downloadingError: false,
  },
  selected: initialSelectedState,
}

const getDataFromResponse = (response, oldList, silentReloading) => {
  // {
  //   data: {
  //     success: true,
  //     data: {
  //       data: [],
  //       current_page: 1,
  //       last_page: 1,
  //       per_page: 15,
  //     },
  //     message: '....'
  //   },
  //   status: 200,
  //   statusText: 'OK',
  //   headers: {},
  // }

  // and sometimes without pagination ->
  // {
  //   {
  //     data: [],
  //     message: '....'
  //   },
  //   status: 200,
  //   statusText: 'OK',
  //   headers: {},
  // }
  const tree = response && response.tree ? response.tree : undefined

  const apiResponse = response || {}

  // Support mock api
  let data = []
  if (Array.isArray(apiResponse)) {
    data = apiResponse
  } else if (Array.isArray(apiResponse.data)) {
    data = apiResponse.data
  }

  const pagination = apiResponse.pagination || {}
  const total = pagination.total || 0
  const offset = pagination.offset || 0
  const limit = pagination.limit || pagination.number || 0

  return {
    tree,
    data,
    limit,
    offset,
    total,
  }
}

export const getObjectFromResponse = (response, fromConcept) => {
  // if (response && response.data) {
  //   return response.data
  // }
  if (response && response.date) {
    return {
      ...response,
      date: fromConcept
        ? Math.round(response.date / 1000)
        : response.date * 1000,
      productBrix: {
        ...response.productBrix,
        values: [
          ...(
            (response.productBrix && response.productBrix.values) ||
            []
          ).filter((n) => n),
          undefined,
        ],
      },
      productHardness: {
        ...response.productHardness,
        values: [
          ...(
            (response.productHardness && response.productHardness.values) ||
            []
          ).filter((n) => n),
          undefined,
        ],
      },

      bioLabel: {
        ...response.bioLabel,
        media: fromConcept
          ? [
              ...((response.bioLabel && response.bioLabel.media) || []).filter(
                (n) => Object.keys(n).length > 0
              ),
              {},
            ]
          : [...((response.bioLabel && response.bioLabel.media) || []), {}],
      },
      consumerLabel: {
        ...response.consumerLabel,
        media: fromConcept
          ? [
              ...(
                (response.consumerLabel && response.consumerLabel.media) ||
                []
              ).filter((n) => Object.keys(n).length > 0),
              {},
            ]
          : [
              ...((response.consumerLabel && response.consumerLabel.media) ||
                []),
              {},
            ],
      },
      label: {
        ...response.label,

        media: fromConcept
          ? [
              ...((response.label && response.label.media) || []).filter(
                (n) => Object.keys(n).length > 0
              ),
              {},
            ]
          : [...((response.label && response.label.media) || []), {}],
      },
      media: [
        ...(response.media || []).filter((n) => Object.keys(n).length > 0),
        {},
      ],
    }
  }
  return response
}

const stateObject =
  (actionTypes, initialState = defaultState) =>
  (state = initialState, { type, payload, meta, error }) => {
    switch (type) {
      case 'RESET': {
        return initialState
      }
      case actionTypes.SET_DATA: {
        return {
          ...state,
          crud: {
            ...state.crud,
            object: { ...state.crud.object, ...payload },
            savedState: false,
            savingState: false,
            savingStateError: false,
          },
        }
      }
      case actionTypes.SAVE_STATE: {
        return {
          ...state,
          crud: {
            ...state.crud,
            savedState: false,
            savingState: true,
            savingStateError: false,
          },
        }
      }
      case actionTypes.SAVED_STATE: {
        if (error) {
          return {
            ...state,
            crud: {
              ...state.crud,
              savedState: false,
              savingState: false,
              savingStateError: payload.message,
            },
          }
        }
        return {
          ...state,
          crud: {
            ...state.crud,
            savedState: true,
            savingState: false,
            savingStateError: false,
          },
        }
      }
      case actionTypes.SAVING_STATE: {
        return {
          ...state,
          crud: {
            ...state.crud,
            savedState: false,
            savingState: true,
          },
        }
      }
      case actionTypes.DOWNLOAD: {
        return {
          ...state,
          download: {
            id: payload.id,
            downloading: true,
            downloaded: false,
            downloadingError: false,
          },
        }
      }
      case actionTypes.SAVE_TOKEN: {
        return {
          ...state,
          savedToken: false,
          savingTokenError: false,
          savingToken: true,
        }
      }
      case actionTypes.SAVED_TOKEN: {
        if (error) {
          return {
            ...state,
            savedToken: true,
            savingTokenError: true,
            savingToken: false,
          }
        } else {
          return {
            ...state,
            savedToken: true,
            savingTokenError: false,
            savingToken: false,
          }
        }
      }
      case actionTypes.DOWNLOADED: {
        if (error) {
          return {
            ...state,
            download: {
              id: null,
              downloading: false,
              downloaded: false,
              downloadingError: true,
            },
          }
        }
        return {
          ...state,
          download: {
            id: null,
            response: payload,
            downloading: false,
            downloaded: true,
            downloadingError: false,
          },
        }
      }
      case actionTypes.CLEAR_DOWNLOAD: {
        return {
          ...state,
          download: {
            response: null,
            downloading: false,
            downloaded: false,
            downloadingError: false,
          },
        }
      }
      case actionTypes.REMOVE_SELECTED: {
        return {
          ...state,
          selected: [],
        }
      }
      case actionTypes.SELECT: {
        const alreadySelected = state.selected.some(
          (it) => it.id === payload.id
        )
        const isSingleSelect = meta && meta.singleSelect

        let selected
        if (isSingleSelect) {
          selected = [payload]
        } else {
          selected = alreadySelected
            ? state.selected.filter((it) => it.id !== payload.id)
            : [...state.selected, payload]
        }

        return {
          ...state,
          selected,
        }
      }
      case actionTypes.TOGGLE_SELECT_ALL: {
        const selectedAll = state.selected.length === state.list.data.length
        return {
          ...state,
          selected: selectedAll ? [] : state.list.data || [],
        }
      }
      case actionTypes.LOAD: {
        let object = undefined
        let loading = true

        if (payload && payload.id) {
          object = payload
        } else {
          loading = false
        }
        // Let's give our users the fastest response possible without networking
        if (meta && meta.cacheFromList && object && object.id) {
          const cachedObject = state.list.data.find((it) => it.id === object.id)

          if (cachedObject) {
            object = cachedObject
            loading = false
            meta.cachedFromList = true
          }
        }

        return {
          ...state,
          crud: {
            ...initialCrudState,
            loaded: false,
            loading,
            object,
          },
        }
      }
      case actionTypes.LOADED: {
        if (error) {
          return {
            ...state,
            crud: {
              ...initialCrudState,
              loadingError: true,
              loadingErrorMessage: getErrorMessage(payload),
            },
          }
        }
        return {
          ...state,
          crud: {
            ...initialCrudState,
            loaded: true,
            created: false,
            updated: false,
            object: getObjectFromResponse(payload),
          },
        }
      }
      case actionTypes.CREATE: {
        return {
          ...state,
          crud: {
            ...state.crud,
            object: payload || state.crud.object,
            creating: true,
            created: false,
            creatingError: false,
          },
        }
      }
      case actionTypes.CREATED: {
        if (error) {
          return {
            ...state,
            crud: {
              ...state.crud,
              creating: false,
              created: false,
              creatingError: true,
              creatingErrorMessage: getErrorMessage(payload),
            },
          }
        }
        const object = getObjectFromResponse(payload)
        return {
          ...state,
          list: {
            ...state.list,
            data: [...state.list.data, object],
          },
          crud: {
            ...state.crud,
            object,
            created: true,
            creating: false,
            creatingError: false,
          },
        }
      }
      case actionTypes.UPDATE: {
        return {
          ...state,
          crud: {
            ...state.crud,
            updating: true,
            updated: false,
            updatingError: false,
          },
        }
      }
      case actionTypes.UPDATED: {
        if (error) {
          return {
            ...state,
            crud: {
              ...state.crud,
              updating: false,
              updated: false,
              updatingError: true,
              updatingErrorMessage: getErrorMessage(payload),
            },
          }
        }
        const object = getObjectFromResponse(payload)

        return {
          ...state,
          list: {
            ...state.list,
            data: state.list.data.map((it) =>
              it.id === object.id ? { ...it, ...object } : it
            ),
          },
          crud: {
            ...state.crud,
            object,
            updated: true,
            updating: false,
            updatingError: false,
          },
        }
      }
      case actionTypes.REMOVE: {
        return {
          ...state,
          crud: {
            ...state.crud,
            removing: true,
            removingError: false,
          },
        }
      }
      case actionTypes.REMOVED: {
        if (error) {
          return {
            ...state,
            crud: {
              ...state.crud,
              removing: false,
              removingError: true,
              removingErrorMessage: getErrorMessage(payload),
            },
          }
        }
        // const object = getObjectFromResponse(payload)
        return {
          ...state,
          list: {
            ...state.list,
            data: state.list.data.filter((it) => it.id !== payload.id),
          },
          crud: {
            ...state.crud,
            removing: false,
            removingError: false,
          },
        }
      }
      // case actionTypes.SET_LIST_PARAMS:
      case actionTypes.LOAD_LIST:
      case actionTypes.RELOAD_LIST: {
        const { silentReloading } = meta || {}
        const { params } = payload || {}
        return {
          ...state,
          list: {
            ...initialListState,
            tree: silentReloading ? state.list.tree : [],
            offset: silentReloading ? state.list.offset : 0,
            limit: silentReloading ? state.list.limit : 0,
            total: silentReloading ? state.list.total : 0,
            loadedList: silentReloading
              ? state.list.loadedList
              : initialListState.loadedList,
            loadingList: true,
            reloadingList: actionTypes.RELOAD === type,
            params: params || state.list.params,
            data: silentReloading ? state.list.data : [],
          },
        }
      }
      case actionTypes.LOADED_LIST: {
        if (error) {
          return {
            ...state,
            list: {
              ...initialListState,
              params: state.list.params,
              loadingListError: true,
              loadingListErrorMessage: getErrorMessage(payload),
            },
          }
        }
        const { silentReloading } = meta || {}
        const data = getDataFromResponse(payload, state.list, silentReloading)
        const stopLoadingMore = data.limit + data.offset >= data.total
        return {
          ...state,
          list: {
            ...initialListState,
            stopLoadingMore,
            loadedList: true,
            params: state.list.params,
            ...data,
          },
        }
      }
      case actionTypes.LOAD_MORE_LIST: {
        const { total, offset } = state.list
        if (offset >= total) {
          return state
        }
        return {
          ...state,
          list: {
            ...state.list,
            loadingMoreList: true,
          },
        }
      }
      case actionTypes.LOADED_MORE_LIST: {
        if (error) {
          return {
            ...state,
            list: {
              ...state.list,
              loadingMoreList: false,
              loadingMoreListError: true,
              loadingMoreListErrorMessage: getErrorMessage(payload),
            },
          }
        }
        const { data, tree, total, offset, limit } =
          getDataFromResponse(payload)
        return {
          ...state,
          list: {
            ...initialListState,
            tree,
            params: state.list.params,
            data: state.list.data.concat(data),
            total,
            offset,
            limit,
          },
        }
      }
      case actionTypes.SOFT_RESET: {
        return {
          ...state,
          crud: {
            ...initialCrudState,
          },
        }
      }

      default:
        return state
    }
  }

export default stateObject
