import React from 'react'

// @ts-ignore
import forgotPasswordHOC from 'react-auth/lib/hocs/forgotPasswordHOC'
import logo from '../vectors/logo-color.png'
import {
  Image,
  NativeSyntheticEvent,
  ScrollView,
  StyleSheet,
  TextInputChangeEventData,
  View,
} from 'react-native'
import { Form, useFormState } from 'react-native-use-form'
import { Button, HelperText, Text, TextInput } from 'react-native-paper'
import { isNotEmpty, looksLikeMail } from './Login'
import Navigation from '../Navigation'

const styles = StyleSheet.create({
  loginContainer: {
    textAlign: 'center',

    display: 'flex',
    flex: 1,
    // minHeight: 100 + 'vh',

    backgroundColor: '#FFF',

    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    marginTop: 15,
    marginBottom: 15,
  },
  // overlay: {
  //   // backgroundColor: 'rgba(0,0,0,0.3)',
  //   height: '100%',
  //   width: '100%',
  //   top: 0,
  // },
  link: {
    color: '#8e8e8e',
    textAlign: 'right',
    // justifyItems: 'flex-end',
    // float: 'right',
    // width: '100%',
  },
  card: {
    width: 400,
    flexDirection: 'column',
    // @ts-ignore
    justifyContent: 'center',
    alignItems: 'center',
    // textAlign: 'center',
    minHeight: 100,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    borderRadius: 4,
  },
  input: {
    width: '100%',
    marginBottom: 8,
    marginTop: 8,
    // fontWeight: 600,
  },
  cardContent: {
    // paddingBottom:,
    padding: 20,
    width: '100%',
  },
  buttonRoot: {
    padding: 8,
    // paddingRight: 8,
    marginTop: 20,
    // marginBottom: -110,
  },
  buttonIcon: {
    marginLeft: 8,
  },
  progress: {
    marginLeft: 8,
  },
  buttonLabel: {
    textTransform: 'none',
    // fontSize: 17,
    // fontWeight: 600,
    color: '#fff',
    // color: '#005490',
  },
  snackbar: {
    // minWidth: '100%!important',
    margin: 0,
    // boxSizing: 'border-box',
  },
})
type ThemedLoginPropsType = {
  sending: boolean
  error: any
  onSubmit: () => any
  onChangeField: (key: string, value: any) => any
  values: {
    email: string
  }
  componentId: any
  completed: boolean
}
function ThemedForgotPassword({
  sending,
  error,
  onSubmit,
  values,
  onChangeField,
  completed,
  componentId,
}: ThemedLoginPropsType) {
  console.log(error)
  const [{ errors, submit, formProps, hasError }, { email }] = useFormState(
    {
      email: values.email,
    },
    {
      onSubmit: () => {
        onSubmit()
      },
    }
  )
  const change = (
    key: string,
    e: NativeSyntheticEvent<TextInputChangeEventData>
  ) => {
    const element = e.target as unknown as HTMLInputElement
    const value = element.value
    // TODO: implement clearErrors function here
    onChangeField(key, value)
  }
  return (
    <ScrollView
      contentContainerStyle={styles.loginContainer}
      keyboardShouldPersistTaps={'handled'}
      scrollEnabled={true}
    >
      <View style={styles.card}>
        <Button onPress={() => Navigation.pop(componentId)}>
          <Image
            style={{
              alignSelf: 'center',
              marginTop: 15,
              // marginBottom: 0,
              width: 200,
              height: 45,
            }}
            source={{ uri: logo }}
          />
        </Button>
        <View style={styles.cardContent}>
          {completed && (
            <Text style={{ margin: 10, marginLeft: 0, fontWeight: 'normal' }}>
              {'Er is een mail verstuurd naar '}
              {values.email}
              {' met instructies om je wachtwoord te veranderen.'}
            </Text>
          )}
          {!completed && (
            <Form {...formProps}>
              <Text style={{ margin: 10, marginLeft: 0, fontWeight: 'normal' }}>
                {
                  'Wij controleren of er een bestaand account gekoppeld is aan het ingevulde e-mailadres.'
                }
              </Text>
              <TextInput
                mode="outlined"
                error={hasError('email')}
                {...email('email', {
                  validate: (v: string) => {
                    if (!isNotEmpty(v)) {
                      return 'E-mailadres is verplicht'
                    } else if (!looksLikeMail(v)) {
                      return 'Controleer of het E-mailadres correct is'
                    }
                    return true
                  },
                })}
                label={'E-mailadres'}
                onChange={(e) => change('email', e)}
              />
              <HelperText type="error" visible={hasError('email')}>
                {errors.email}
              </HelperText>

              <Button mode="contained" onPress={submit} loading={sending}>
                {sending ? 'Wachtwoord opvragen...' : 'Wachtwoord opvragen'}
              </Button>

              <HelperText type="error" visible={error && error.message}>
                {`${error}`}
              </HelperText>
            </Form>
          )}
        </View>
      </View>
    </ScrollView>
  )
}
export default forgotPasswordHOC(ThemedForgotPassword, [{ name: 'email' }])
